const load = () => {
    const nav = document.querySelector('#nav');
    const menu = document.querySelector('#menu');
    const menuToggle = document.querySelector('.nav__toggle');
    const navLinks = Array.from(document.querySelectorAll('.nav__link'));
    let isMenuOpen = false;

    const handleClick = e => {
        e.preventDefault();
        toggleMenu()
    }

    const toggleMenu = () => {
        isMenuOpen = !isMenuOpen;
        // toggle a11y attributes and active class
        menuToggle.setAttribute('aria-expanded', String(isMenuOpen));
        menu.hidden = !isMenuOpen;
        nav.classList.toggle('nav--open');
    }

    // TOGGLE MENU ACTIVE STATE
    // navLinks.forEach(nl => nl.addEventListener('click', handleClick))
    if (menuToggle)
        menuToggle.addEventListener('click', handleClick);
    navLinks.map(n => n.addEventListener('click', toggleMenu))

    // TRAP TAB INSIDE NAV WHEN OPEN
    if (nav)
        nav.addEventListener('keydown', e => {
            // abort if menu isn't open or modifier keys are pressed
            if (!isMenuOpen || e.ctrlKey || e.metaKey || e.altKey) {
                return;
            }

            // listen for tab press and move focus
            // if we're on either end of the navigation
            const menuLinks = menu.querySelectorAll('.nav__link');
            if (e.keyCode === 9) {
                if (e.shiftKey) {
                    if (document.activeElement === menuLinks[0]) {
                        menuToggle.focus();
                        e.preventDefault();
                    }
                } else if (document.activeElement === menuToggle) {
                    menuLinks[0].focus();
                    e.preventDefault();
                }
            }
        });
}
load()