import { $q, $$, on, emit } from "../dom/events.js"
const TIMEOUT = 4000

let to, arrow;
const move = () => {
	to = setTimeout(() => {
		arrow.classList.add("bounce")
	}, TIMEOUT)
}

const checkScroll = e => {
	clearTimeout(to)
	if (window.scrollY > 70) arrow.classList.add("hide")
	else {
		arrow.classList.remove("hide")
		move();
	}
}

const scrollArrow = () => {
	arrow = $q(".scroll-arrow");
	if (!arrow) return
	move();
	checkScroll();
	window.addEventListener("scroll", checkScroll)
	window.addEventListener("resize", checkScroll)
}
scrollArrow();